import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Text, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 90px 0",
	"lg-padding": "80px 0 80px 0",
	"background": "--color-lightD1",
	"quarkly-title": "Content-15"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"justify-content": "space-around",
			"lg-flex-direction": "column",
			"flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"lg-width": "100%",
			"display": "flex",
			"flex-direction": "row",
			"justify-content": "center",
			"lg-margin": "0px 0px 50px 0px",
			"margin": "0px 0px 35px 0px",
			"sm-flex-direction": "column"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/1-3.jpg?v=2024-06-03T09:49:58.056Z",
			"display": "block",
			"object-fit": "cover",
			"margin": "0px 5px 0px 0px",
			"width": "26%",
			"sm-width": "100%",
			"sm-margin": "0 0 10px 0"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-2.jpg?v=2024-06-03T09:49:58.056Z",
			"display": "block",
			"object-fit": "cover",
			"margin": "0px 0px 0px 5px",
			"width": "37%",
			"sm-width": "100%",
			"sm-margin": "0 0 10px 0"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/1-2.jpg?v=2024-06-03T09:49:58.043Z",
			"display": "block",
			"object-fit": "cover",
			"margin": "0px 0px 0px 5px",
			"width": "37%",
			"sm-width": "100%",
			"sm-margin": "0px 0px 0px 0"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"justify-content": "center",
			"lg-padding": "0px 0px 0px 0",
			"lg-width": "100%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"color": "#474e53",
			"text-align": "left",
			"max-width": "640px",
			"children": "Дозвольте нам допомогти вам знайти ідеальний автомобіль для ваших потреб. Наша команда тут, щоб гарантувати, що ваш досвід прокату автомобіля буде вільним від стресу від початку до кінця."
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Image {...override("image")} />
				<Image {...override("image1")} />
				<Image {...override("image2")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;